<template>
  <div>
    <el-card>

      <div style="padding-left:50px" v-if="activeName=='1'">
        <div class="msg" v-loading="loading">
          <div class="left">
            <img src="http://xsoss2016.oss-cn-hangzhou.aliyuncs.com/melyshop/image/jushuitan-logo.png" alt="">
            <div style="margin-left:10px">
              <div class="flex flex-align-center">
                <div class="title">聚水潭绑定信息</div>
                <el-button type="text" style="margin-left:10px" v-if="form.Id" @click="enable(true,true)">修改</el-button>
              </div>
              <div style="margin-top:20px">
                <div v-if="form.Id">
                  <div class="flex flex-align-center" style="margin-bottom:5px">
                    <div class="font-14 color-999999" style="width:100px">APP Key</div>
                    <div class="font-14 color-606266">{{form.app_key}}</div>
                  </div>
                  <div class="flex flex-align-center" style="margin-bottom:5px">
                    <div class="font-14 color-999999" style="width:100px">APP Secret</div>
                    <div class="font-14 color-606266">{{form.app_secret}}</div>
                  </div>
                  <div class="flex flex-align-center" style="margin-bottom:5px">
                    <div class="font-14 color-999999" style="width:100px">RefreshToken</div>
                    <div class="font-14 color-606266">{{form.JuShuiTanRefreshToken}}</div>
                  </div>
                  <div class="flex flex-align-center" style="margin-bottom:5px">
                    <div class="font-14 color-999999" style="width:100px">店铺编号</div>
                    <div class="font-14 color-606266">{{form.shop_id}}</div>
                  </div>
                </div>
                <div v-else class="font-14 color-999999">启用聚水潭，绑定聚水潭账号数据</div>
              </div>
              <div class="flex flex-align-center" style="margin-top:30px" v-if="form.IsOpen">
                <el-button @click="pushorder">立即推送订单</el-button>
              </div>
            </div>
          </div>
          <div class="right">
            <div class="flex flex-align-center">
              <div class="font-14 color-606266">{{form.IsOpen?'已启用':'未启用'}}</div>
              <el-button type="text" style="margin-left:10px" @click="enable(form.IsOpen,false)">{{form.IsOpen?'停用':'启用'}}</el-button>
            </div>
          </div>
        </div>
        <div style="margin-top:20px">
          <div class="font-14 color-303133" style="margin-bottom:5px">订单推送规则：</div>
          <div class="font-14 color-303133" style="margin-bottom:5px">1.订单支付成功后1小时，系统自动推送订单至聚水潭ERP</div>
          <div class="font-14 color-303133" style="margin-bottom:5px">2.仅推送待发货的快递发货单</div>
          <div class="font-14 color-303133" style="margin-bottom:5px">3.若订单里有退款中的商品，待退款处理完成（退款单状态更新为退款关闭或退款成功），再推送订单</div>
          <div class="font-14 color-303133" style="margin-bottom:5px">4.若订单在推送前，商品退款成功，将扣减已经退款成功数量，推送剩余数量</div>
          <div class="font-14 color-303133" style="margin-bottom:5px">5.若急需发货，可点击[立即推送订单]按钮，推送订单数据；手动点击推单，不受订单支付时间限制</div>
        </div>
      </div>
    </el-card>

    <el-dialog :visible.sync="useShow" :title="ischange?'修改聚水潭绑定信息':'启用聚水潭'" width="600px" v-loading="userloading" custom-class="dialog-body-paddingTop-10">
      <div style="padding-left:14px">
        <div class="flex flex-align-center">
          <div class="font-14 color-999999">请从聚水潭开放平台、聚水潭管理后台获取下方配置信息。</div>
        </div>
        <div style="margin-top:28px">
          <div class="flex flex-align-center" style="margin-bottom:20px">
            <div class="dialog-label">APP Key：</div>
            <el-input v-model="editform.app_key" style="width:300px" maxlength="50" placeholder="请输入APP Key"></el-input>
          </div>
          <div class="flex flex-align-center" style="margin-bottom:20px">
            <div class="dialog-label">APP Secret：</div>
            <el-input v-model="editform.app_secret" style="width:300px" maxlength="50" placeholder="请输入APP Secret"></el-input>
          </div>
          <div class="flex flex-align-center" style="margin-bottom:20px">
            <div class="dialog-label">RefreshToken：</div>
            <el-input v-model="editform.JuShuiTanRefreshToken" style="width:300px" maxlength="50" placeholder="请输入RefreshToken"></el-input>
          </div>
          <div class="flex flex-align-center" style="margin-bottom:20px">
            <div class="dialog-label">店铺编号：</div>
            <div class="flex flex-align-center">
              <el-input v-model="editform.shop_id" style="width:300px" placeholder="请输入店铺编号"
               onkeyup="if(this.value.length==1){this.value=this.value.replace(/[^0-9]/g,'')}else{this.value=this.value.replace(/\D/g,'')}"
               onafterpaste="if(this.value.length==1){this.value=this.value.replace(/[^0-9]/g,'')}else{this.value=this.value.replace(/\D/g,'')}"></el-input>
            </div>
          </div>
        </div>
        <div class="flex flex-align-center flex-justify-center" style="margin-top:30px">
          <el-button @click="useShow=false">取消</el-button>
          <el-button type="primary" @click="savemsg">{{ischange?'保存':'保存并启用'}}</el-button>
        </div>
      </div>
    </el-dialog>

  </div>
</template>

<script>
import buttonPermissions from '@/components/buttonPermissions';
import {
  juShuiTanConfiginit,
  juShuiTanConfigedit,
  juShuiTanConfigpushJuShuiTanOrder
} from "@/api/goods"
import {
  commonallexpress
} from "@/api/api"
export default {
  components: {
    buttonPermissions
  },
  data () {
    return {
      activeName:'1',
      form:{

      },
      loading:false,
      useShow:false,
      ischange:true,
      userloading:false,
      editform:{
        Id:0,
        IsOpen:false,
        app_key:'',
        app_secret:'',
        shop_id:'',
        JuShuiTanRefreshToken:'',
      },
      keywords:'',
      tablelist:[],
      tableloading:false,
      pagesize:20,
      currentPage:1,
      total:0,
      rowmsg:{},
      mappingShow:false,
      maploading:false,
    }
  },
  mounted () {
    this.getinfo()
  },
  methods: {
    enable(IsOpen,ischange){
      this.ischange = ischange
      if(ischange||!IsOpen){
        this.editform = JSON.parse(JSON.stringify(this.form))
        this.useShow = true
      }else{
        this.stopuse()
      }
    },
    async stopuse(){
      try{
        this.loading = true
        let res = await juShuiTanConfigedit({JuShuiTanInfo:{
          ...this.form,
          IsOpen:false,
        }})
        if(res.IsSuccess){
          this.$message.success('已停用聚水潭')
          this.getinfo()
        }
      }finally{
        this.loading = false
      }
    },
    async savemsg(){
      if(!this.editform.app_key){
        this.$message.error('请输入聚水潭APP Key')
        return 
      }else if(!this.editform.app_secret){
        this.$message.error('请输入APP Secret')
        return 
      }else if(!this.editform.JuShuiTanRefreshToken){
        this.$message.error('请输入RefreshToken')
        return 
      }else if(!this.editform.shop_id){
        this.$message.error('请输入店铺编号')
        return 
      }else if(this.editform.shop_id<=0||this.editform.shop_id>999999999){
        this.$message.error('请输入正确的店铺编号')
        return 
      }
      try{
        this.userloading = true
        let res = await juShuiTanConfigedit({JuShuiTanInfo:{
          ...this.editform,
          IsOpen:true
        }})
        if(res.IsSuccess){
          this.useShow = false
          this.$message.success('启用成功')
          this.getinfo()
        }
      }finally{
        this.userloading = false
      }
    },
    async getinfo(){
      try{
        this.loading = true
        let res = await juShuiTanConfiginit()
        if(res.IsSuccess){
          this.form = res.Result
        }
      }finally{
        this.loading = false
      }
    },
    async pushorder(){
      try{
        let res = await juShuiTanConfigpushJuShuiTanOrder()
        if(res.IsSuccess){
          this.$message.success('操作成功')
        }
      }finally{
        //
      }
    },
  }
}
</script>

<style lang="less" scoped>
.msg{
  width: 800px;
  background: #FFFFFF;
  border-radius: 2px 2px 2px 2px;
  border: 1px solid #EBEEF5;
  padding: 16px;
  display: flex;
  justify-content: space-between;
  .left{
    display: flex;
    align-items: flex-start;
    img{
      width: 100px;height: 100px;
    }
    .title{
      font-size: 16px;
      font-weight: bold;
      color: #303133;
    }
  }
}
.font-14{
  font-size: 14px;
  line-height: 1.5;
}
.color-999999{
  color: #999999;
}
.color-606266{
  color: #606266;
}
.color-303133{
  color: #303133;
}
.flex{
  display: flex;
}
.flex-align-center{
  align-items: center;
}
.flex-justify-center{
  justify-content: center;
}
.dialog-label{
  font-size: 14px;
  color: #606266;
  line-height: 36px;
  margin-right: 10px;
  flex-shrink: 0;
  width: 120px;
  text-align: right;
  font-weight: bold;
  &::before{
    content:'*';
    color:#f56c6c;
    margin-right:4px
  }
}
.text-right{
  width: 130px;
  text-align: right;
}
</style>